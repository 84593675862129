

import ContractsList from "../contracts/contracts-table.vue";

export default {
    name: "EventContractsCard",
    components: {ContractsList},
    mixins: [],
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {
    },
}
