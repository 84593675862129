
import moment from "moment";
import TableActions from "../table-actions.vue";

export default {
    name: "ImportFromSessionsModal",
    components: {TableActions},
    props: {},
    data() {
        return {
            events: [],
            uuid: this.generateUUID(),
            tableLoading: false,
            config: {
                columns: [
                    {
                        headline: "Event",
                    }, {
                        headline: "Description",
                        breakpoint: "all",
                    }, {
                        headline: "Location",
                        breakpoint: "lg"
                    }, {
                        headline: "Start",
                        sort: true,
                    }, {
                        headline: "End",
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: 'end'
                    }
                ],
                pagination: 10
            },
            customFields: [],
            step: 1,
            invitedByField: null,
            selectedEventId: null,
        }
    },
    computed: {
        rows() {
            return this.events.map((event) => {
                return [
                    {text: event.eventName},
                    {text: event.eventDescription},
                    {text: event.eventLocation},
                    {
                        text: event.eventStart ? this.formatDate(event.eventStart) : "",
                        sortValue: event.eventStart ? moment(event.eventStart).unix() : 0,
                    },
                    {
                        text: event.eventEnd ? this.formatDate(event.eventEnd) : "",
                        sortValue: event.eventEnd ? moment(event.eventEnd).unix() : 0,
                    },
                    {
                        type: 'actions',
                        text: "Actions",
                        eventId: event.id,
                    },
                ];
            });
        }
    },
    watch: {},
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadEvents();
        });
    },
    methods: {
        loadEvents() {
            this.tableLoading = true;
            this.$axios.get('/api/sessions/events')
                .then((response) => {
                    this.events = response.data;
                })
                .finally(() => {
                    this.tableLoading = false;
                })
        },
        importEvent(id) {
            this.selectedEventId = id;
            this.$axios.get('/api/sessions/event/' + id)
                .then((response) => {
                    if (response.data.customFields && response.data.customFields.length > 0) {
                        this.customFields = response.data.customFields;
                        this.step = 2;
                    } else {
                        this.importEventProceed(id);
                    }
                });
        },
        importEventProceed(id) {
            this.tableLoading = true;

            const filteredEvents = this.events.filter(e => e.id === id);
            if (filteredEvents.length !== 1) {
                return;
            }
            const eventToImport = filteredEvents[0];

            if (!confirm('The event "' + eventToImport.eventName +
                '" including all meeting requests and meeting participants will be imported.' +
                ' Do you want to continue?')
            ) {
                this.tableLoading = false;
                return;
            }

            this.$axios.post(`/api/sessions/events/import`, {
                eventId: id,
                invitedByField: this.invitedByField
            }).then((response) => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Event will be imported",
                    message: "The event is being imported in the background and will be available shortly.",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                    this.$emit('import-triggered');
                })
            });
        }
    }
}
