

export default {
    name: "ConflictsWarning",
    props: {
        conflictingEvents: {
            type: Array,
            default: () => []
        }
    }
}

