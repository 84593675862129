
import moment from "moment";
import SaveButton from "../save-button.vue";
import LoadingSpinner from "../loading-spinner.vue";
import HintText from "../hint-text.vue";
import iriPreparation from '~/mixins/iri-preparation';
import events from "~/mixins/events";

export default {
    name: "EventBulkOperationModal",
    components: {HintText, LoadingSpinner, SaveButton},
    mixins: [iriPreparation, events],
    props: {
        id: {
            type: String,
            default: "eventBulkOperationModal"
        },
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        },
        contact: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            bulkFiltersKey: null,
            eventIds: [],
            numberRoadshows: null,
            numberOtherEvents: null,
            operation: null,
            isRunning: false,
            isLoading: false,
            blastEmailTemplate: null,
            blastFeedbackTemplate: 'TemplatePostEventFeedback',
            postEventFeedbackEvent: null,
            firstFollowUpEmail: null,
            firstFollowUpEmailSentAt: null,
            mostRecentEmails: [],
            downloadQuery: null,
        }
    },
    computed: {
        bulkOptions() {
            const options = [
                {
                    value: 'create_blast_email_draft',
                    label: 'Create Blast Email Draft'
                }, {
                    value: 'create_blast_feedback_draft',
                    label: 'Create Blast Feedback Draft',
                },
                {
                    value: 'create_weekly_investor_mail',
                    label: 'Create Weekly Investor Mail'
                },
                {
                    value: 'download_as_excel_file',
                    label: 'Download as Excel file'
                },
            ];

            if (this.contact) {
                options.push({
                    value: 'send_confirmation_email',
                    label: 'Send Confirmation Email'
                });
            }

            return options;
        },
        blastEmailTemplates() {
            return [{
                value: 'TemplateRoadshowsAndOtherEvents',
                label: 'Multiple Other Events (and Roadshows)',
                disabled: this.numberOtherEvents === 0,
            }, {
                value: 'TemplateRoadshows',
                label: 'Multiple Roadshows',
                disabled: this.numberOtherEvents > 0 || this.numberRoadshows === 0,
            }, {
                value: 'TemplateRoadshow',
                label: 'Single Roadshow',
                disabled: this.numberRoadshows !== 1 || this.numberOtherEvents > 0,
            }, {
                value: 'TemplateOtherEvent',
                label: 'Single Other Event',
                disabled: this.numberRoadshows > 0 || this.numberOtherEvents !== 1
            }];
        },
        blastFeedbackTemplates() {
            const createInvestorFollowUpDraftDisabled =
                this.numberRoadshows === null ||
                this.numberOtherEvents === null ||
                this.numberRoadshows !== 1 ||
                this.numberOtherEvents !== 0;

            return [{
                value: 'TemplatePostEventFeedback',
                label: 'Post event feedback',
            }, {
                value: 'TemplateInvestorFirstFollowUp',
                label: 'Investor first follow-up' + (createInvestorFollowUpDraftDisabled ? ' (only for single roadshow)' : ''),
                disabled: createInvestorFollowUpDraftDisabled,
            }, {
                value: 'TemplateInvestorSecondFollowUp',
                label: 'Investor second follow-up' + (createInvestorFollowUpDraftDisabled ? ' (only for single roadshow)' : ''),
                disabled: createInvestorFollowUpDraftDisabled,
            }];
        },
    },
    watch: {
        blastEmailTemplates: {
            handler(templates) {
                this.blastEmailTemplate = templates.find(x => !x.disabled).value;
            },
            immediate: true,
            deep: true,
        },
        firstFollowUpEmail: {
            handler(email) {
                if (email && email.sentAt) {
                    // Remove the timezone part of the datetime
                    const split = email.sentAt.split('T');
                    const sentAt = `${split[0]}T${split[1].split(/[+-]/)[0]}`;
                    this.firstFollowUpEmailSentAt = moment(sentAt).format('YYYY-MM-DD HH:mm');
                } else {
                    this.firstFollowUpEmailSentAt = null;
                }
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.saveBulkFilters();
            this.loadMostRecentEmails();
        });
    },
    methods: {
        loadMostRecentEmails() {
            return this.$axios.get('/api/emails/most_recent').then(response => {
                this.mostRecentEmails = response.data['hydra:member'];
            });
        },
        submitted() {
            this.$emit("submitted");
        },
        submitForm() {
            switch (this.operation) {
                case "create_weekly_investor_mail":
                    this.prepareWeeklyInvestorMail();
                    break;
                case "create_blast_email_draft":
                    this.prepareBlastEmailDraft();
                    break;
                case "create_blast_feedback_draft":
                    this.prepareBlastFeedbackDraft();
                    break;
                case "download_as_excel_file":
                    this.downloadEvents();
                    break;
                case "send_confirmation_email":
                    this.sendConfirmationEmail();
                    break;
                default:
                    alert("Unknown operation.");
            }
        },
        saveBulkFilters() {
            this.isLoading = true;
            this.$axios.post('/api/events/save_bulk_filters', {
                filters: this.filters,
            }).then(response => {
                this.bulkFiltersKey = response.data.key;
                this.eventIds = response.data.eventIds;
                this.numberRoadshows = response.data.numberRoadshows;
                this.numberOtherEvents = response.data.numberOtherEvents;

                this.isLoading = false;
            });
        },
        prepareBlastEmailDraft() {
            this.isRunning = true;
            this.$axios.post('/api/events/prepare_blast_email_draft', {
                key: this.bulkFiltersKey,
                blastEmailTemplate: this.blastEmailTemplate,
            })
                .then(response => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Email draft has been prepared.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.isRunning = false;
                    this.submitted();
                    this.$router.push('/emails/' + response.data.id);
                })
                .catch(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "danger",
                        title: "Error",
                        message: "Email draft could not be created.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.isRunning = false;
                });
        },
        prepareWeeklyInvestorMail() {
            this.isRunning = true;
            this.$axios.post('/api/events/prepare_blast_email_draft', {
                key: this.bulkFiltersKey,
                blastEmailTemplate: 'WeeklyInvestorMail',
            })
                .then(response => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Email draft has been prepared.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.isRunning = false;
                    this.submitted();
                    this.$router.push('/emails/' + response.data.id);
                })
                .catch(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "danger",
                        title: "Error",
                        message: "Email draft could not be created.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.isRunning = false;
                });
        },
        prepareBlastFeedbackDraft() {
            this.isRunning = true;

            if (this.blastFeedbackTemplate === 'TemplatePostEventFeedback' && !this.postEventFeedbackEvent) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "warning",
                    title: "Event missing",
                    message: "Please select the event for the post event feedback email.",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });

                this.isRunning = false;
                return;
            }

            if (this.blastFeedbackTemplate === 'TemplateInvestorSecondFollowUp' && !this.firstFollowUpEmail) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "warning",
                    title: "First Follow-up Email missing",
                    message: "Please select the first follow-up email.",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });

                this.isRunning = false;
                return;
            }

            this.$axios.post('/api/events/prepare_blast_feedback_draft', {
                key: this.bulkFiltersKey,
                blastFeedbackTemplate: this.blastFeedbackTemplate,
                mainEventId: this.postEventFeedbackEvent ? this.postEventFeedbackEvent.id : null,
                firstFollowUpEmailId: this.firstFollowUpEmail ? this.firstFollowUpEmail.id : null,
                firstFollowUpEmailSentAt: this.firstFollowUpEmailSentAt,
            })
                .then(response => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Email draft has been prepared.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.isRunning = false;
                    this.submitted();
                    this.$router.push('/emails/' + response.data.id);
                })
                .catch(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "danger",
                        title: "Error",
                        message: "Email draft could not be created.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.isRunning = false;
                });
        },
        downloadEvents() {
            this.downloadQuery = {
                filters: this.filters,
            };
            this.$emit('downloadEvent', this.downloadQuery)
        },
        sendConfirmationEmail() {
            console.log("Event IDs", this.eventIds);

            this.doSendConfirmationEmail(this.contact.id, this.eventIds);
        }
    }
}
