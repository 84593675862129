
import moment from "moment";
import modals from "../../mixins/modals";
import events from "../../mixins/events";
import interactions from "../../mixins/interactions";
import InteractionsTable from "../interactions/interactions-table";
import SaveButton from "../save-button";
import EditInteractionModal from "../interactions/edit-interaction-modal";
import ConfirmModal from "../confirm-modal";
import ShowNoteModal from "../notes/show-note-modal";
import EditNoteModal from "../notes/edit-note-modal";
import InteractionDownloadModal from "../interactions/interaction-download-modal";
import EventMeeting from "./event-meeting";
import iriPreparation from '~/mixins/iri-preparation'

export default {
    name: "EventInteractionsCard",
    components: {
        InteractionDownloadModal,
        EditNoteModal,
        ShowNoteModal,
        ConfirmModal, EditInteractionModal, SaveButton, EventMeeting, InteractionsTable
    },
    mixins: [events, interactions, iriPreparation, modals],
    props: {
        event: {
            type: Object,
            required: true,
        },
        showFilters: {
            type: Boolean,
            required: false,
            default: true,
        },
        eventsUpdated: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            addingNewInteraction: false,
            interactionToAdd: null,
            templates: {
                interaction: {},
                interactionParticipant: {},
            },
            interactionTypes: [],
        };
    },
    computed: {
        tableKey() {
            return this.eventsUpdated; // + this.tableUpdated;
        },
        noteKey() {
            return JSON.stringify(this.noteToEdit);
        },
    },
    watch: {
        tableUpdated() {
            this.$emit('event-updated');
        },
    },
    mounted() {
        if (this.isUserWithRoleCorporateAccess) {
            this.loadTemplates();
            this.loadEventOptions();
        }
    },
    methods: {
        loadTemplates() {
            this.$axios.get(`/api/events/templates_for_event/${this.event.id}`).then(response => {
                this.templates = response.data;
            });
        },
        loadEventOptions() {
            this.$axios.get("/api/events/options_for_event?2023-04-05").then(response => {
                this.interactionTypes = response.data.interactionTypes.map(interactionType => {
                    interactionType['@id'] = `/api/interaction_types/${interactionType.id}`;
                    return interactionType;
                });
            });
        },
        addNewInteraction() {
            const newInteraction = structuredClone(this.templates.interaction);

            newInteraction.event = this.event;

            newInteraction.interactionType = this.interactionTypeForInteractions;
            newInteraction.timezone = this.event.timezone;

            this.interactionToAdd = newInteraction;
            this.addingNewInteraction = true;
        },
        saveInteraction() {

            // Do some hemdsärmlig validation

            if (!this.interactionToAdd.interactionType) {
                alert("Please set a type for this interaction.");
                return false;
            }

            if (!this.interactionToAdd.start) {
                alert("Please set a start date/time for this interaction.");
                return false;
            }

            if (!this.interactionToAdd.end) {
                alert("Please set an end date/time for this interaction.");
                return false;
            }

            // Now save the meeting

            this.loading = true;

            const finalInteraction = this.prepareInteractionOfEvent(this.event, this.interactionToAdd, false);

            // Check if there are participants or that it is a Blocker
            if (["BLOCKER", "PANEL", "PRESENTATION", "ICAL_EXPORT"].includes(finalInteraction.interactionType?.VID) &&
                ["/api/interaction_types/5", "/api/interaction_types/6", "/api/interaction_types/7", "/api/interaction_types/8"].includes(finalInteraction.interactionType) &&
                finalInteraction.interactionParticipants.length === 0
            ) {
                alert("Please add participants for this interaction.");
                this.loading = false;
                return false;
            }

            // If it is a Panel or Presentation, there needs to be a subject
            if ((["PANEL", "PRESENTATION"].includes(finalInteraction.interactionType?.VID) ||
                ["/api/interaction_types/5", "/api/interaction_types/6", "/api/interaction_types/7"].includes(finalInteraction.interactionType)) &&
                !finalInteraction.subject
            ) {
                alert("Please add a subject for this interaction.");
                this.loading = false;
                return false;
            }

            this.$axios.post('/api/interactions', finalInteraction)
                .then((response) => {
                    let title = "Interaction Created";
                    let message = "The interaction was successfully created";
                    if (response.data.isBlocker) {
                        title = "Blocker Created";
                        message = "The blocker was successfully created";
                    }
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title,
                        message,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });

                    this.tableUpdated = moment().unix();

                    // Reset the interaction in the form
                    this.cancelNewInteraction();
                }).finally(() => {
                    this.loading = false;
                });
        },
        cancelNewInteraction() {
            this.addingNewInteraction = false;
            this.interactionToAdd = null;
        },
        showMeetingsPerCompanyStatistics() {
            this.$emit('show-meetings-per-company-statistics')
        },

    }
}
