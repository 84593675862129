
import moment from "moment";
import ContactsTable from "../contacts/contacts-table";
import EventParticipantsList from "./event-participants-list";

export default {
    name: "EventParticipantsCard",
    components: {
        ContactsTable,
        EventParticipantsList,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        eventsUpdated: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            tableUpdated: moment().unix(),
        };
    },
    computed: {
        tableKey() {
            return this.eventsUpdated + this.tableUpdated;
        },
    },
    watch: {
        tableUpdated() {
            this.$emit('event-updated');
        },
    },
    methods: {
        participantsListUpdateHandler() {
            this.tableUpdated = moment().unix();
        },
        eventContactsUpdated() {
            this.$emit('event-updated');
        },
    },
}
