
import HintText from "../hint-text";
import InfoText from "../info-text";

export default {
    name: "SendModal",
    components: {InfoText, HintText},
    props: {
        numberOfRecipients: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            confirmed: false,
            name: "",
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (!this.confirmed) {
                this.$emit('canceled')
            }
            this.confirmed = false;
        })
    },
    methods: {
        confirm() {
            this.confirmed = true;
            this.$emit('confirmed', {name: this.name});
        },
    }
}
