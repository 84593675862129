
import HintText from "../hint-text";

export default {
    name: "SaveTemplateModal",
    components: {HintText},
    props: {
        email: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            confirmed: false,
            name: "",
            isPrivate: true,
        }
    },
    computed: {
        ownEmail() {
            return this.email && (!this.email.id || !this.email.authorUser || this.email.authorUser.id === this.userInfo.id);
        }
    },
    watch: {
        email: {
            handler(val) {
                if (val && val.name) {
                    this.name = val.name
                }
                this.isPrivate = !!(val && val.isPrivate);

            },
            immediate: true
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (!this.confirmed) {
                this.$emit('canceled')
            }
            this.confirmed = false;
        })
    },
    methods: {
        confirm() {
            this.confirmed = true;
            this.$emit('confirmed', {name: this.name, private: this.isPrivate});
        },
    }
}
