
export default {
    name: "FavStar",
    props: {
        contactId: {
            type: Number,
            required: false,
            default: null,
        },
        companyId: {
            type: Number,
            required: false,
            default: null,
        },
        contactGroupId: {
            type: Number,
            required: false,
            default: null
        },
        companyGroupId: {
            type: Number,
            required: false,
            default: null
        },
        initialIsFavorite: {
            type: [Object, Boolean],
            required: false,
            default: null,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        noPadding: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
            default: "warning",
            validator: function validator(value) {
                return ["warning", "info", "primary", "danger", "warning", "success"].includes(value);
            },
        },
    },
    data() {
        return {
            uuid: this.generateUUID(),
            isFavorite: null,
            isLoading: false,
        }
    },
    computed: {
        isForContact() {
            return this.contactId !== null;
        },
        isForCompany() {
            return this.companyId !== null;
        },
        isForContactGroup() {
            return this.contactGroupId !== null;
        },
        isForCompanyGroup() {
            return this.companyGroupId !== null;
        },
        id() {
            if (this.isForContact) {
                return this.contactId;
            } else if (this.isForCompany) {
                return this.companyId;
            } else if (this.isForContactGroup) {
                return this.contactGroupId;
            } else if (this.isForCompanyGroup) {
                return this.companyGroupId;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }
        },
        iri() {
            if (this.isForContact) {
                return `/api/contacts/${this.contactId}`;
            } else if (this.isForCompany) {
                return `/api/companies/${this.companyId}`;
            } else if (this.isForContactGroup) {
                return `/api/contact_lists/${this.contactGroupId}`;
            } else if (this.isForCompanyGroup) {
                return `/api/company_groups/${this.companyGroupId}`;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }
        },
        buttonTitle() {
            if (this.isForContact) {
                return this.isFavorite ? "Remove as favorite contact" : "Add as favorite contact";
            } else if (this.isForCompany) {
                return this.isFavorite ? "Remove as favorite company" : "Add as favorite company";
            } else if (this.isForContactGroup) {
                return this.isFavorite ? "Remove as favorite contact group" : "Add as favorite contact group";
            } else if (this.isForCompanyGroup) {
                return this.isFavorite ? "Remove as favorite company group" : "Add as favorite company group";
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }
        },
    },
    mounted() {
        this.loadIsFavorite();
    },
    methods: {
        loadIsFavorite() {
            if (this.initialIsFavorite !== null) {
                this.isFavorite = this.initialIsFavorite;
            } else {
                this.isLoading = true;
                this.$axios.get(`${this.iri}/is_favorite`)
                    .then((response) => {
                        this.isFavorite = response.data;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        updateFavorite() {
            this.isLoading = true;

            let url;
            if (this.isForContact) {
                url = '/api/user_favorites_contacts';
            } else if (this.isForCompany) {
                url = '/api/user_favorites_companies';
            } else if (this.isForContactGroup) {
                url = '/api/user_favorites_contact_groups';
            } else if (this.isForCompanyGroup) {
                url = '/api/user_favorites_company_groups';
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "Neither for contact nor for company!";
            }

            if (this.isFavorite) {
                this.$axios.delete(`${url}/${this.isFavorite.id}`).then(() => {
                    this.isFavorite = false;
                    this.$emit("removed");
                }).finally(() => {
                    this.isLoading = false;
                });

            } else {
                let data;
                if (this.isForContact) {
                    data = {
                        user: `/api/users/${this.userInfo.id}`,
                        contact: this.iri,
                    };
                } else if (this.isForCompany) {
                    data = {
                        user: `/api/users/${this.userInfo.id}`,
                        company: this.iri,
                    };
                } else if (this.isForContactGroup) {
                    data = {
                        user: "/api/users/" + this.userInfo.id,
                        contactList: '/api/contact_lists/' + this.contactGroupId
                    };
                } else if (this.isForCompanyGroup) {
                    data = {
                        user: "/api/users/" + this.userInfo.id,
                        companyGroup: '/api/company_groups/' + this.companyGroupId
                    };
                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw "Neither for contact nor for company!";
                }

                this.$axios.post(url, data).then((response) => {
                    this.isFavorite = response.data;
                    this.$emit("added");
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    }
}
