
import ContactName from "./contacts/contact-name";
import emailPopoverActionsMixin from "~/mixins/email-popover-actions";

export default {
    name: "EditingHistory",
    components: {
        ContactName,
    },
    mixins:[
        emailPopoverActionsMixin,
    ],
    props: {
        ajaxUrl: {
            type: String,
            required: false,
            default: null,
        },
        entries: {
            type: Array,
            required: false,
            default: null,
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
        showHeadlines: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: [Number, Boolean],
            default: 10,
        }
    },
    data() {
        const additionalProperties = {};
        if (!this.showHeadlines) {
            additionalProperties.hideHeadlineBreakpoint = 'all';
        }
        return {
            editingHistoryConfig: {
                columns: [
                    {
                        headline: "User",
                        ...additionalProperties,
                    },
                    {
                        headline: "Description",
                        ...additionalProperties,
                    },
                    {
                        headline: "Date",
                        align: "end",
                        ...additionalProperties,
                    },
                ],
                pagination: this.pagination,
                search: this.showSearch,
                ajaxUrl: this.ajaxUrl,
            },
        }
    },
    computed: {
        editingHistoryRows() {
            if (!this.entries || !Array.isArray(this.entries)) {
                return []
            }
            return this.entries.map((history) => {
                return [
                    {type: "user", user: history.user},
                    {text: history.action},
                    {type: "date", date: history.createdAt},
                ]
            })
        },
    },
    mounted() {
    },
    methods: {
    },
}

