

import SaveButton from "../save-button.vue";

export default {
    name: "QuestionsEditModal",
    components: {SaveButton},
    props: {
        event: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: String,
            default: 'questions',
        }
    },
    data() {
        return {
            uuid: this.generateUUID(),
            questions: "",
            followUpItems: "",
            otherItems: "",
            loading: false
        }
    },
    computed: {},
    watch: {
        event: {
            handler() {
                this.questions = this.event.questions;
                this.followUpItems = this.event.followUpItems;
                this.otherItems = this.event.otherItems;
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {

    },
    methods: {
        submitForm() {
            this.loading = true;
            this.$axios.patch(`/api/events/${this.event.id}`, {
                questions: this.questions,
                followUpItems: this.followUpItems,
                otherItems: this.otherItems,
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then((response) => {
                this.$emit("event-updated", response.data);
                this.$refs.modal.hide();
            }).catch((error) => {
                console.error(error);
            })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
