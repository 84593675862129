
import moment from 'moment';
import 'moment-timezone';
import HintText from "../hint-text";
import InfoText from "../info-text";

export default {
    name: "ScheduleModal",
    components: {InfoText, HintText},
    props: {
        email: {
            type: Object,
            default: null,
        },
        numberOfRecipients: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            scheduledDate: null,
            scheduledTime: null,
            scheduledTimezone: moment.tz.guess(),
            name: "",
        }
    },
    computed: {
        initialTimezones() {
            return this.userInfo.most_used_timezones.map(tz => {
                return {
                    label: tz,
                    value: tz,
                }
            });
        },
        isEmailToContactGroup() {
            if(!this.email) {
                return false;
            }
            for(const recipient of this.email.emailRecipients) {
                if (recipient.contactList) {
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        email: {
            handler(val) {
                if (val && val.scheduledAt && val.scheduledAtTimezone) {
                    this.scheduledTimezone = val.scheduledAtTimezone;

                    const datetime = moment(val.scheduledAt).tz(val.scheduledAtTimezone);

                    this.scheduledTime = datetime.format("HH:mm");
                    this.scheduledDate = datetime.format("YYYY-MM-DD");

                } else if (val && (!val.scheduledAt || !val.scheduledAtTimezone)) {
                    this.scheduledTimezone = moment.tz.guess();
                    this.scheduledDate = moment.tz(moment(),this.scheduledTimezone).format("YYYY-MM-DD");
                    this.scheduledTime = moment.tz(moment(),this.scheduledTimezone).add(30, "minutes").format("HH:mm");
                }
            },
            immediate: true,
        }
    },
    methods: {
        confirm() {
            this.$formulate.submit("schedule-form")

        },
        doConfirm() {
            const scheduledAt = moment.tz(this.scheduledDate + " " + this.scheduledTime, this.scheduledTimezone).toISOString();
            const scheduledAtTimezone = this.scheduledTimezone;

            this.$emit('saved', {scheduledAt, scheduledAtTimezone, name: this.name});
        }
    }
}
