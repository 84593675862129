
import TableFilters from "../table-filters";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";

export default {
    name: "RecipientDetailsModal",
    components: {QuintableSearchInput, TableFilters},
    props: {
        emailId: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            tableUpdated: this.generateUUID(),
            onlyIncludedContacts: false,
            onlyExcludedContacts: false,
            emailStatistics: null,
        };
    },
    computed: {
        config() {
            return {
                pagination: 20,
                ajaxUrl: `/api/emails/recipients/${this.emailId}`,
                search: true,
                columns: [
                    {headline: "Name", sort: true},
                    {headline: "Email", sort: true, breakpoint: 'lg'},
                    {headline: "Company", sort: true, breakpoint: 'xl'},
                    {headline: "Excluded from Emails", sort: true, breakpoint: "lg"},
                    {headline: "Active", sort: true, breakpoint: "md"},
                    {headline: "Temporary", sort: true, breakpoint: 'md'},
                    {headline: "Sent", sort: true},
                    {headline: "Read", sort: true},
                ]
            }
        },
        filters() {
            return {
                onlyIncludedContacts: this.onlyIncludedContacts,
                onlyExcludedContacts: this.onlyExcludedContacts,
            };
        },
        emailReadPercentage() {
            if (this.emailStatistics === null || this.emailStatistics.toRecipients === 0) {
                return null;
            }

            return Math.floor(this.emailStatistics.readToRecipients / this.emailStatistics.toRecipients * 100);
        },
    },
    watch: {
        onlyIncludedContacts() {
            if (this.onlyIncludedContacts) {
                this.onlyExcludedContacts = false;
            }
        },
        onlyExcludedContacts() {
            if (this.onlyExcludedContacts) {
                this.onlyIncludedContacts = false;
            }
        },
        emailId() {
            this.loadEmailStatistics();
        },
    },
    mounted() {
        this.loadEmailStatistics();
    },
    methods: {
        loadEmailStatistics() {
            this.$axios.get(`/api/emails/recipient_statistics/${this.emailId}`).then(response => {
                this.emailStatistics = response.data;
            });
        },
        resetFilters() {
            this.tableUpdated = this.generateUUID();
            this.onlyIncludedContacts = false;
            this.onlyExcludedContacts = false;
        },
        download() {
            window.open(`/api/emails/recipients/export/${this.emailId}`, '_blank').focus();
        }
    },
}
