
export default {
    name: "EventCompanyTicker",
    props: {
        ticker: {
            type: String,
            default: null,
        },
        companyId: {
            type: Number,
            required: true,
        },
        eventId: {
            type: Number,
            required: true,
        },
        infoLine: {
            type: String,
            default: null,
        }
    },
    methods: {
        copyCompanyEventInformation(line) {
            navigator.clipboard.writeText(line);
            const toastId = this.generateUUID();
            this.addToast({
                type: "success",
                title: "Information has been copied to your clipboard",
                // message: "",
                id: toastId,
            });
            this.$nextTick(() => {
                this.toggleToast(toastId);
            })
        },
        openCompanyEventInformationModal(companyId, eventId) {
            this.$emit('open-company-event-information-modal', companyId, eventId);
        }
    }
}
