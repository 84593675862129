
import events from "../../mixins/events";
import modals from "../../mixins/modals";
import LoadingSpinner from "../loading-spinner";
import colors from "../../mixins/colors";
import EditEventModal from "./edit-event-modal";
import QuestionsEditModal from "./questions-edit-modal.vue";

export default {
    name: "EventGoalsCard",
    components: {QuestionsEditModal, LoadingSpinner, EditEventModal},
    mixins: [modals, colors, events],
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            modal: null,
            currentTargetNumberByGoalIDs: [],
            showModal: false,
            activeQuestionsTab: "questions",
        };
    },
    mounted() {
        this.loading = true;
        this.loadCurrentTargetNumbers();
    },
    methods: {
        editEvent() {
            this.showModal = true;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.editEventModal);
            })
        },
        eventUpdated() {
            this.closeModal(this.modal);
            this.showModal = false;
            this.$emit('event-updated');
        },
        loadCurrentTargetNumbers() {
            this.$axios.get(`/api/events/target_numbers/${this.event.id}`)
                .then((response) => {
                    this.currentTargetNumberByGoalIDs = response.data;
                }).finally(() => {
                    this.loading = false;
                });
        },
        showMeetingsPerCompanyStatistics() {
            this.$emit('show-meetings-per-company-statistics')
        },
        async openQuestionsEditModal(tab = "questions") {
            this.activeQuestionsTab = tab;
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.questionsEditModal)
        }
    },
}
