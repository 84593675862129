

export default {
    name: "FancyTooltip",
    props: {
        topY: {
            type: Number,
            default: null
        },
        bottomY: {
            type: Number,
            default: null
        },
        leftX: {
            type: Number,
            default: null
        },
        rightX: {
            type: Number,
            default: null
        },
        visible: {
            type: Boolean,
            required: true,
        }
    },
}
