
import HintText from "../hint-text";
import SaveButton from "../save-button.vue";
import ConflictsWarning from "./conflicts-warning.vue";

export default {
    name: "EventConfirmModal",
    components: {SaveButton, ConflictsWarning, HintText},
    props: {
        event: {
            type: Object,
            required: true
        },
        toConfirm: {
            type: Boolean,
            required: true
        },
        toCancel: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            sendMail: true,
            confirmed: false,
            uuid: this.generateUUID(),
            eventWorkingCopy: {},
            loading: false,
            previousStatus: "",
            creatorName: null,
            conflictingEvents: [],
        }
    },
    watch: {
        event: {
            handler() {
                this.resetEventWorkingCopy();
            },
            immediate: true,
        },
        eventWorkingCopy() {
            this.creatorName = this.eventWorkingCopy.createdByUser?.contact.name
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.sendMail = true;
        });
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (!this.confirmed) {
                this.$emit('canceled', this.item)
            }
            this.confirmed = false;
        })
    },
    methods: {
        confirm() {
            this.confirmed = true;
            this.$emit('confirmed', this.item);
        },
        async resetEventWorkingCopy() {
            this.eventWorkingCopy = structuredClone(this.event);
            this.creatorName = this.eventWorkingCopy.createdByUser?.contact.name;
            this.previousStatus = this.event.status;
            this.eventWorkingCopy.status = null;
            await this.$nextTick();
            this.loadConflicts();
        },
        submit() {
            if (this.toConfirm && this.eventWorkingCopy.status === null) {
                alert('Status is required');
                return;
            }
            if (this.toCancel) {
                this.cancelEvent()
                return;
            }
            this.confirmOrRejectEvent()
        },
        confirmOrRejectEvent() {
            this.loading = true
            this.$axios.post(`/api/events/set_status/${this.eventWorkingCopy.id}`, {
                reject_reason: this.eventWorkingCopy.rejectReason,
                status: this.toConfirm ? this.eventWorkingCopy.status : "Declined",
                sendMail: this.sendMail
            }).then((res) => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: this.toConfirm ? "Event Confirmed" : "Event Declined",
                    message: this.toConfirm ? "Event has been confirmed" : "Event has been declined",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.loading = false
                this.$emit('event-updated');
            });
        },
        loadConflicts() {
            if (this.eventWorkingCopy.start && this.eventWorkingCopy.end && this.eventWorkingCopy.timezone) {

                this.$axios.post('/api/events/check_conflicts', {
                    start: this.eventWorkingCopy.start,
                    end: this.eventWorkingCopy.end,
                    timezone: this.eventWorkingCopy.timezone,
                    eventIdsToIgnore: [this.eventWorkingCopy.id],
                })
                    .then((response) => {
                        this.conflictingEvents = response.data;
                    });
            }
        },
        cancelEvent() {
            this.loading = true
            this.$axios.post(`/api/events/set_status/${this.eventWorkingCopy.id}`, {
                reject_reason: this.eventWorkingCopy.rejectReason,
                status: "Canceled",
                sendMail: this.sendMail
            }).then((res) => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Event Canceled",
                    message: "Event has been canceled",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.loading = false
                this.$emit('event-updated');
            });
        }
    }
}
