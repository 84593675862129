

import SaveButton from "../../save-button.vue";

export default {
    name: "ConnectToSessionsModal",
    components: {SaveButton},
    mixins: [],
    props: {
        id: {
            type: String,
            default: "connectToSessionsModal"
        },
        event: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            sessionsEvent: null,
            invitedByField: null,
        }
    },
    computed: {},
    watch: {},
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {

        });
    },
    methods: {
        loadSessionsEvents() {
            this.$axios.get()
        },
        submitForm() {
            this.loading = true;
            this.$axios.post("/api/events/update_sessions_id", {
                eventId: this.event.id,
                sessionsId: this.sessionsEvent.id,
                invitedByField: this.invitedByField,
            })
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Event connected to sessions",
                        message: 'Meeting Request will be listed after synchronization completed.',
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                    this.$emit('event-updated');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
