
import moment from "moment";
import modals from "../../mixins/modals";
import AddContactModal from "../contacts/add-contact-modal";
import {ATTENDANCE_OPTIONS} from "../../mixins/attending";

export default {
    name: "EventParticipant",
    components: {
        AddContactModal,
    },
    mixins: [modals],
    props: {
        /** @deprecated */
        ajaxSave: {
            type: Boolean,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        participants: {
            type: [Array, null],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            ATTENDANCE_OPTIONS,
            isUnchanged: true,
            loading: false,
            participantsWorkingCopy: (this.participants || []).map(
                (eventContactParticipation, index) => ({...eventContactParticipation, key: index})
            ),
            nextKey: (this.participants || []).length,
            modal: null,
            addContactIndex: null,
            addContactReset: moment().unix(),
        };
    },
    computed: {
        filledParticipants() {
            return this.participantsWorkingCopy.filter(eventContactParticipation => eventContactParticipation.contact);
        },
        filledParticipantTypes() {
            return this.participantsWorkingCopy.filter(eventContactParticipation => eventContactParticipation.sessionsType);
        },
        allNewParticipantsHaveSameType() {
            return [...new Set(this.participantsWorkingCopy.map(eventContactParticipation => eventContactParticipation.sessionsType))].length === 1
        }
    },
    methods: {
        addParticipant() {
            this.participantsWorkingCopy.push({
                contact: null,
                isAttending: true,
                hasAttended: null,
                sessionsType: null,
                key: this.nextKey,
            });
            this.isUnchanged = false;
            this.nextKey++;
        },
        async doAjaxSave(sendConfirmation, registerInSessions = false) {
            if (this.event.sessionsId && registerInSessions && !confirm("Participation will be updated in Sessions as well. Do you want to continue?")) {
                return;
            }

            this.loading = true;

            const participantsToSend = this.participantsWorkingCopy.map(eventContactParticipation => ({
                contact: eventContactParticipation.contact['@id'],
                sessionsType: eventContactParticipation.sessionsType,
                event: this.event['@id'],
                isAttending: eventContactParticipation.isAttending,
                hasAttended: eventContactParticipation.hasAttended,
                confirmationSent: sendConfirmation,
                registerInSessions
            }));

            const responsePromises = participantsToSend.map(eventContactParticipation => {
                return this.$axios.post('/api/contact_event_participations', eventContactParticipation);
            });

            await Promise.all(responsePromises).then(responses => {
                if (sendConfirmation) {
                    console.log('responses', responses);
                    const contactEventParticipationIds = responses.map(response => response.data.id);

                    this.$axios.post(`/api/events/prepare_event_registration_email_draft/${this.event.id}`, {
                        contactEventParticipationIds,
                    }).then(response => {

                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Success",
                            message: "Email draft has been prepared.",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });

                        this.$router.push('/emails/' + response.data.id);
                    });

                } else {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Contact has been added.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });

                    this.updateEventProperty();
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        /** @deprecated */
        doLocalSave() {
            this.updateEventProperty();
        },
        removeParticipant(index) {
            this.participantsWorkingCopy.splice(index, 1);
        },
        async save(sendConfirmation, registerInSessions = false) {
            this.participantsWorkingCopy = this.filledParticipants;
            if (this.ajaxSave) {
                await this.doAjaxSave(sendConfirmation, registerInSessions);
            } else {
                this.doLocalSave(sendConfirmation);
            }
        },
        updateEventProperty() {
            this.$emit("update", this.participantsWorkingCopy);
            this.participantsWorkingCopy = [];
            this.isUnchanged = true;
        },
        addContact(index) {
            this.addContactIndex = index;

            this.modal = this.openModal(this.$refs.addContactModal);
        },
        contactCreated(newContact) {
            this.participantsWorkingCopy[this.addContactIndex].contact = newContact;
            this.addContactIndex = null;

            this.closeModal(this.modal);
        },
        resetAddContact() {
            this.addContactReset = moment().unix();
        },
    }
}
