
// import SearchSelect from "../search-select";

// import SearchSelect from "../search-select.vue";


import SaveButton from "../save-button.vue";
import UploadDropZone from "../upload-drop-zone.vue";
import LoadingSpinner from "../loading-spinner.vue";

export default {
    name: "EventLuciteModal",
    components: {LoadingSpinner, UploadDropZone, SaveButton},
    props: {
        eventId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            logoError: false,
            logoUploaded: false,
            imageSrc: null,
            useOwnLogo: true,
            user: null,
            highlightDropArea: false,
            importFile: "",
            fileUrl: "",
            logoFile: null,
            load: false,

            serializedFile: {
                type: "",
                file: null,
            },

            generatedFile: "",
            companyLogo: "",
            company: null,
            companyId: null,
            companyLogoUrl: null,
            location: "",
            date: "",
            title: "",
            event: null,
            selectedCompany: null,
            comp: null,
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loading = true;
            this.resetAll();
            if (!this.companyLogoUrl) {
                this.useOwnLogo = true;
            }
            this.loadEvent(); // renamed from loadTransaction
            this.getTombstoneData();
        });

    },
    methods: {
        // ... other methods ...
        getOrdinalSuffix(day) {
            if (day % 10 === 1 && day !== 11) {
                return day + "st";
            }
            if (day % 10 === 2 && day !== 12) {
                return day + "nd";
            }
            if (day % 10 === 3 && day !== 13) {
                return day + "rd";
            }
            return day + "th";
        },
        loadEvent() { // renamed from loadTransaction
            this.$axios.get(`/api/events/${this.eventId}`) // update the API endpoint
                .then(response => {
                    if (this.location === null) {
                        this.location = response.data.location;
                    }
                    this.title = response.data.subject;
                    this.event = response.data;
                    if (this.date === null || this.date === "") {
                        if (typeof response.data.start === 'string' || typeof response.data.start === 'number') {
                            const date = new Date(response.data.start)
                            if (isNaN(date.getTime())) {
                                // response.data.start was a string or number, but not a valid date
                                console.error('Invalid date:', response.data.start);
                            } else {
                                const formattedDate = date.toLocaleDateString('en-us', {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric"
                                })
                                const dayWithSuffix = this.getOrdinalSuffix(date.getDate());
                                this.date = `${formattedDate.split(' ')[0]} ${formattedDate.split(' ')[1]} ${dayWithSuffix} ${formattedDate.split(' ')[3]}`;
                            }
                        } else {
                            // response.data.start was not a string or number
                            console.error('Invalid date:', response.data.start);
                        }
                    }
                });

        },
        onCompanySelected() {
            // this.companyLogoUrl = this.selectedCompany.logoUrl;
            if (!this.selectedCompany) {
                return;
            }
            this.$axios.get(this.selectedCompany)
                .then(response => {
                    this.comp = response.data.name;
                    this.companyLogoUrl = response.data.logoUrl;
                    if (this.companyLogoUrl) {
                        fetch(this.companyLogoUrl)
                            .then(response => response.blob())
                            .then(blob => {
                                // Create a new FileReader object
                                const reader = new FileReader();

                                // Set the onload event handler
                                reader.onload = () => {
                                    // Set the serializedFile property to the Data URL of the logo file
                                    this.serializedFile.file = {
                                        logoFile: reader.result,
                                        extension: blob.type.split('/')[1], // Get the file extension from the MIME type
                                    };
                                };

                                // Read the blob as a Data URL
                                reader.readAsDataURL(blob);

                                this.useOwnLogo = false;
                            });
                    } else {
                        this.useOwnLogo = true;
                    }

                });
        },
        getTombstoneData() {
            this.$axios.get(`/api/events/${this.eventId}?groups[]=event:lucite_data`)
                .then((response) => {
                    const luciteData = response.data.luciteData;
                    this.title = luciteData.title;
                    this.location = luciteData.location;
                    this.date = luciteData.date;
                    if (luciteData.image !== null) {
                        this.imageSrc = 'data:image/png;base64,' + luciteData.image;
                        this.fileData = luciteData.image;
                    }
                    this.fileUrl = luciteData.logo;
                    if (this.fileUrl) {
                        this.logoUploaded = true;
                        this.serializedFile.file = {
                            logoFile: this.fileUrl,
                            extension: 'png', // Update this if you have the file extension
                        };
                    }
                }).finally(() => {
                    this.loading = false;
                });

        },
        submitForm() {
            if (this.useOwnLogo && !this.logoUploaded) {
                // Show an error message
                this.logoError = true;
                alert('Please upload a logo.');
                // Stop the form submission
                return;
            }
            this.load = true;

            this.$axios.post('/api/events/generateTombstone', {
                eventId: this.eventId,
                image: this.serializedFile,
                title: this.title,
                location: this.location,
                date: this.date,
            })
                .then((response) => {
                    // this.generatedFile = response.data;
                    // this.generatedFile = response.data.image;
                    // this.forceFileDownload(response.data.image);

                    // this.imageSrc = window.URL.createObjectURL(new Blob([response.data]));
                    this.imageSrc = 'data:image/png;base64,' + response.data.image;
                    this.fileData = response.data.image;

                }).finally(() => {
                    this.loading = false;
                    this.load = false;
                });
        },
        downloadImage() {
            // Convert base64 data to Blob
            const byteCharacters = atob(this.fileData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'image/png'});
            // // Create a temporary link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.title + '_' + this.eventId + '_' + 'banner' + '.png';

            // Simulate a click to trigger download
            link.click();
        },

        dragover(event) {
            event.preventDefault();
            this.highlightDropArea = true;
        },
        dragleave(event) {
            // Clean up
            this.highlightDropArea = false;
        },
        drop(event) {
            event.preventDefault();
            this.highlightDropArea = false;

            if (event.dataTransfer.files[0]) {
                this.setFileData(event.dataTransfer.files)
            }
        },
        setFileData(files) {
            if (files[0]) {
                this.logoFile = files[0];
                this.fileUrl = URL.createObjectURL(files[0]);

                const reader = new FileReader();
                reader.readAsDataURL(this.logoFile)
                // directory
                reader.onerror = () => {
                    console.log("Error!");
                };
                reader.onload = () => {
                    this.serializedFile.file = {
                        logoFile: reader.result,
                        extension: this.logoFile.name.split('.').pop(),
                    };
                };
                this.logoUploaded = true;
                this.logoError = false;
            }
        },

        resetFile() {
            this.fileUrl = null;
            this.logoFile = null;
            this.logoUploaded = false;
            this.logoError = false;
        },

        resetAll() {
            this.logoError = false;
            this.logoUploaded = false;
            this.imageSrc = null;
            this.useOwnLogo = true;
            this.user = null;
            this.highlightDropArea = false;
            this.importFile = "";
            this.fileUrl = "";
            this.logoFile = null;
            this.load = false;
            this.serializedFile = {
                type: "",
                file: null,
            };
            this.generatedFile = "";
            this.companyLogo = "";
            this.company = null;
            this.companyId = null;
            this.companyLogoUrl = null;
            this.location = "";
            this.date = "";
            this.title = "";
            this.event = null;
            this.selectedCompany = null;
        },

    }
}
