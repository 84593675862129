

import LoadingSpinner from "../loading-spinner";

export default {
    name: "MeetingsPerCompanyStatisticsModal",
    components: {
        LoadingSpinner,
    },
    mixins: [],
    props: {
        event: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            statistics: null,
            sortOrder: [{
                index: 1,
                asc: false,
            }],
        };
    },
    computed: {
        statisticsUrl() {
            return ('/api/events/meetings_per_company_statistics/' + this.extractNumericId(this.event));
        },
        detailsTableConfig() {
            return {
                columns: [
                    {
                        headline: "Company",
                        sort: true,
                    },
                    {
                        headline: "Meetings",
                        sort: true,
                    },
                ],
                pagination: 10,
            };
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadStatistics();
        });
    },
    methods: {
        loadStatistics() {
            this.$axios.get(this.statisticsUrl)
                .then((response) => {
                    this.statistics = response.data;
                });
        },
        restrictPrecision(number, maxDigitsAfterDot) {
            if (isNaN(number)) {
                return number;
            } else {
                return number.toFixed(maxDigitsAfterDot).replace(/\.?0+$/, '');
            }
        },
    }
}
