

import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";
import iriPreparation from "../../mixins/iri-preparation";

export default {
    name: "EventAttestationFormModal",
    components: {
        LoadingSpinner,
        SaveButton,
    },
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            required: false,
            default: "eventAttestationFormModal",
        },
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            eventAttestation: null,
        }
    },
    computed: {
        showComplianceWarning() {
            return !!(this.eventAttestation && (
                this.eventAttestation.inPresenceOfMNPI ||
                this.eventAttestation.violationOfRegulation ||
                this.eventAttestation.inappropriateInformationShared
            ));
        }
    },
    watch: {},
    mounted() {
        if (this.$refs.modal) {
            this.$refs.modal.addEventListener('show.bs.modal', () => {
                this.eventAttestation = null; // reset it first
                this.loadAttestation(this.event.id);
            });
        }
    },
    methods: {
        formSubmitHandler() {
            this.saving = true;
            let promise;

            this.eventAttestation.user = this.prepareIri(this.eventAttestation.user);

            if (this.eventAttestation.id) {
                promise = this.$axios.patch(`/api/event_attestations/${this.eventAttestation.id}`, this.eventAttestation, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                });
            } else {
                promise = this.$axios.post('/api/event_attestations', this.eventAttestation);
            }

            promise.then((response) => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Attested",
                    message: "Click here to download your attestation.",
                    onClicked: () => {
                        window.open(this.$axios.defaults.baseURL + "/api/events/attestation_form/" + this.event.id, '_blank')
                    },
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.saving = false;
                this.$emit("finished");
            });
        },
        loadAttestation(eventId) {
            this.$axios.get(`/api/events/attestation/${eventId}`)
                .then((response) => {
                    this.eventAttestation = response.data;
                    // console.log("Response", response.data);
                })
        }
    }
}
