
export default {
    name: "EventVolumeCard",
    props: {
        event: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            rows: null,
            isLoading: false,
        }
    },
    computed: {
        config() {
            return {
                columns: [
                    {
                        headline: "Date",
                        sort: true,
                    }, {
                        headline: "Investors",
                        sort: false,
                    }, {
                        headline: "+15%",
                        sort: true,
                    }, {
                        headline: "-15%",
                        sort: true,
                    },
                    {
                        headline: "Avg. Past 30 Day Vol.",
                        breakpoint: "all",
                    },
                    {
                        headline: "Avg. Next 5 Day Vol.",
                        breakpoint: "all",
                    },
                ],
                pagination: 10,
                sortOrder: [{
                    index: 0,
                    asc: true,
                }]
            }
        }
    },
    mounted() {
        this.loadRows();
    },
    methods: {
        loadRows() {
            this.isLoading = true;
            this.$axios.get(`/api/events/volume/${this.event.id}`)
                .then((response) => {
                    this.rows = response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
}
