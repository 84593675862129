
import SessionsMeetingRequestsTable from "./sessions-meeting-requests-table.vue";

export default {
    name: "SessionsMeetingRequestsCard",
    components: {SessionsMeetingRequestsTable},
    props: {
        event: {
            type: Object,
            required: true,
        }
    }
}
