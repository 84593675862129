
import TableFilters from "../table-filters.vue";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
// import TableActions from "../table-actions.vue";
import modals from "../../mixins/modals";
import TableActions from "../table-actions.vue";
import AddEventQuestionsModal from "./add-event-questions-modal.vue";
import AutosaveCellAnswer from "./autosave-cell-answer.vue";




export default {
    name: "EventQuestionsTable",
    components: {AutosaveCellAnswer, TableActions, QuintableSearchInput, TableFilters, AddEventQuestionsModal}, // TableActions,
    mixins: [modals],
    props: {
        company: {
            type: Object,
        },
    },
    data() {
        return {
            isVisible: false,
            tableUpdated: new Date(),
            modal:null,
            searchQuery: null,
            selectedCompanies: [],
            selectedStatuses: [],
            loadingId: null,
            companyToPass: null,
            questionToPass: null,
            defaultQuestion: false,
        }
    },
    computed: {
        filters() {
            return {
                // companies: this.company ? [this.company.id] : this.selectedCompanies.map(company => company.id),
                // status: this.selectedStatuses,
            }
        },

        config() {
            return {
                columns: [
                    {
                        headline: "Question",
                    },
                    {
                        headline: "Answer",
                    },
                    {
                        headline: "Answered By",
                    },
                    {
                        headline: "Answered At",
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    },
                ],
                search: true,
                // pagination: this.pageSize,
                ajaxUrl: `/api/events/questions/` + (this.company ? this.company.id : ''),
            };
        },
    },
    watch: {
    },

    methods: {
        addQuestion() {
            this.companyToPass = this.company;
            this.defaultQuestion = false;
            this.modal = this.openModal(this.$refs.addEventQuestionsModal);
        },
        modifyDefault(id){
            this.questionToPass = id;
            this.companyToPass = this.company;
            this.defaultQuestion = true;
            this.modal = this.openModal(this.$refs.addEventQuestionsModal);
        },
        modifyNonDefault(id){
            this.questionToPass = id;
            this.companyToPass = this.company;
            this.defaultQuestion = false;
            this.modal = this.openModal(this.$refs.addEventQuestionsModal);
        },
        resetFields() {
            this.companyToPass = null;
            this.questionToPass = null;
            this.defaultQuestion = false;
        },
        handleQuestionsAdded() {
            this.closeModal(this.modal);
            this.refreshTable(); // Refresh the table after adding questions
        },
        refreshTable() {
            // Update the tableUpdated timestamp to refresh the table
            this.companyToPass = null;
            this.questionToPass = null;
            this.tableUpdated = new Date();

        },
        resetFilters() {
            this.$refs.questionsTable.setSearchQuery("");
            this.selectedCompanies = [];
        },
        updateSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
            this.pageChanged = true;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        deleteQuestion(id){
            this.$axios.delete(`/api/question_for_events/${id}`).then((response) => {
                this.refreshTable();
            });
        }

    }
}
