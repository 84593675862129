
export default {
    name: "SelectTestRecipientUserModal",
    data() {
        return {
            testRecipientUser: null,
        }
    },
    methods: {
        sendTestEmail() {
            this.$emit('send-test', this.testRecipientUser.id);
        },
    }
}
