
import moment from "moment";
import debounce from "../../mixins/debounce";
import events from "../../mixins/events";
import SaveButton from "../save-button";
import HintText from "../hint-text";
import {FEEDBACK_STATUS} from "../../mixins/feedback-status";
import EventMeeting from "./event-meeting";
import ConflictsWarning from "./conflicts-warning.vue";
import iriPreparation from '~/mixins/iri-preparation'

export default {
    name: "EditEventModal",
    components: {
        ConflictsWarning,
        HintText,
        EventMeeting,
        SaveButton,
    },
    mixins: [events, iriPreparation, debounce],
    props: {
        id: {
            type: String,
            required: false,
            default: "editEventModal"
        },
        event: {
            type: Object,
            required: true
        },
        companyId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            dateConfig: {
                mode: 'range',
                altInput: true,
                altFormat: "m/d/Y",
                dateFormat: 'Y-m-d',
            },
            loading: false,
            eventWorkingCopy: {},
            interactionWorkingCopies: [],
            selectedEventCompanies: [],
            selectedTeamMemberUsers: [],
            selectedEventCompanyContacts: [],
            templates: {
                eventHasCompany: {},
                eventHasCompanyContact: {},
                eventTeamMember: {},
                eventGoal: {},
                interaction: {},
                interactionParticipant: {},
            },
            temporaryDate: null,
            departments: [],
            eventFormats: [],
            eventTypes: [],
            eventGoalTypes: [],
            interactionTypes: [],
            initialEventCompanyContactOptions: [],
            conflictCheckTimeout: null,
            conflictingEvents: [],
            FEEDBACK_STATUS,
            selectedKeyAccountManageSuggestions: [],
        }
    },
    computed: {
        dates: {
            get() {
                if (this.eventWorkingCopy.start && this.eventWorkingCopy.end) {
                    return `${moment(this.eventWorkingCopy.start).format("YYYY-MM-DD")} to ${moment(this.eventWorkingCopy.end).format("YYYY-MM-DD")}`
                } else {
                    return null;
                }
            },
            set(newValue) {
                if (!newValue) {
                    this.eventWorkingCopy.start = null;
                    this.eventWorkingCopy.end = null;
                } else if (newValue.includes(" to ")) {
                    const split = newValue.split(" to ");
                    this.eventWorkingCopy.start = split[0];
                    this.eventWorkingCopy.end = split[1];
                } else {
                    this.eventWorkingCopy.start = newValue;
                    this.eventWorkingCopy.end = newValue;
                }
            }
        },
        initialTimezones() {
            return this.userInfo.most_used_timezones.map(tz => {
                return {
                    label: tz,
                    value: tz,
                }
            });
        },
        updateUrl() {
            return this.eventWorkingCopy["@id"];
        },
        isNew() {
            return !(this.eventWorkingCopy && this.eventWorkingCopy["@id"]);
        },
        /*
        eventHasCompanies() {
            // FIXME Check if company was part of the event already! + also event team members and event company contacts
            if (!Array.isArray(this.selectedEventCompanies)) {
                const eventHasCompany = structuredClone(this.templates.eventHasCompany);
                eventHasCompany.company = this.selectedEventCompanies['@id'];
                return [eventHasCompany];
            }


            let eventHasCompanies = structuredClone(this.event.eventHasCompanies);
            for (let i = 0; i < this.selectedEventCompanies.length; i++) {

                let companyIncludedAlready = false
                for(let j = 0; j < eventHasCompanies.length; j++) {
                    console.log("Event has company", eventHasCompanies[j]);
                    let companyIri = null;
                    if (typeof eventHasCompanies[j].company === 'string' || eventHasCompanies[j].company instanceof String) {
                        // It's an iri
                        companyIri = eventHasCompanies[j].company;
                    }   else {
                        companyIri = eventHasCompanies[j].company['@id'];
                    }

                    companyIncludedAlready = companyIri === this.selectedEventCompanies[i]['@id'];
                }
                if (companyIncludedAlready) {
                    continue;
                }

                // We have a newly added company here
                const eventHasCompany = structuredClone(this.templates.eventHasCompany);
                eventHasCompany.company = this.selectedEventCompanies[i]['@id'];
                eventHasCompanies.push(eventHasCompany);
            }


            // Make sure all companies are iris
            eventHasCompanies = eventHasCompanies.map((ehc) => {
                ehc.company = this.prepareIri(ehc.company)
                return ehc;
            })

            return eventHasCompanies;

            // return this.selectedEventCompanies.map((company) => {
            //     const eventHasCompany = structuredClone(this.templates.eventHasCompany);
            //     eventHasCompany.company = company['@id'];
            //     return eventHasCompany;
            // });

        },
         */
        eventHasCompanies() {
            if (!Array.isArray(this.selectedEventCompanies)) {
                const eventHasCompany = structuredClone(this.templates.eventHasCompany);
                eventHasCompany.company = this.selectedEventCompanies['@id'];
                return [eventHasCompany];
            }

            return this.selectedEventCompanies.map((company) => {
                const eventHasCompany = structuredClone(this.templates.eventHasCompany);
                eventHasCompany.company = company['@id'];
                return eventHasCompany;
            });
        },
        eventHasCompanyContacts() {
            if (!Array.isArray(this.selectedEventCompanyContacts)) {
                const eventHasCompanyContact = structuredClone(this.templates.eventHasCompanyContact);
                eventHasCompanyContact.contact = this.selectedEventCompanyContacts['@id'];
                return [eventHasCompanyContact];
            }

            return this.selectedEventCompanyContacts.map((contact) => {
                const eventHasCompanyContact = structuredClone(this.templates.eventHasCompanyContact);
                eventHasCompanyContact.contact = contact['@id'];
                return eventHasCompanyContact;
            });
        },
        eventTeamMembers() {
            return this.selectedTeamMemberUsers.map((user) => {
                const eventTeamMember = structuredClone(this.templates.eventTeamMember);
                eventTeamMember.user = user['@id'];
                return eventTeamMember;
            });
        },
        contactParams() {
            let ids = [];
            if (!Array.isArray(this.selectedEventCompanies)) {
                ids.push(this.selectedEventCompanies.id);
            } else {
                ids = this.selectedEventCompanies.map(c => c.id);
            }

            return {
                company: ids
            };
        },
        showCompanyContactSelection() {
            // const isStatusProposed = this.eventWorkingCopy.status === 'Proposed';
            let oneOrMoreCompaniesSelected = false;
            if (this.selectedEventCompanies instanceof Array) {
                oneOrMoreCompaniesSelected = this.selectedEventCompanies && (this.selectedEventCompanies.length > 0);
            } else if (typeof this.selectedEventCompanies === 'object' && this.selectedEventCompanies !== null) {
                oneOrMoreCompaniesSelected = true;
            }

            // return isStatusProposed && oneOrMoreCompaniesSelected;
            return oneOrMoreCompaniesSelected;
        },
        isNotBlocker: {
            get() {
                if (this.eventWorkingCopy && this.eventWorkingCopy.eventType && this.eventWorkingCopy.eventType.title) {
                    return !(this.eventWorkingCopy.eventType.title === 'Blocker');
                } else {
                    return true;
                }
            },
            immediate: true,
            deep: true,
        },
        isBlocker() {
            return !this.isNotBlocker;
        },
        statusOptions() {
            let options = [{value: 'Proposed', label: 'Proposed'}];
            if (this.isUserWithRoleCorporateAccess) {
                options = options.concat([
                    {value: 'In Pipeline', label: 'In Pipeline (not yet finalized)'},
                    {value: 'Released', label: 'Released'},
                    {value: 'Declined', label: 'Declined'},
                    {value: 'Blocker', label: 'Blocker'}
                ])
            }
            return options;
        },
        conflictCheckTrigger() {
            return this.dates + this.timezone;
        },
        companiesSelectUrl() {
            if (!this.eventWorkingCopy.start || !this.eventWorkingCopy.end) {
                return '/api/companies/for_select';
            }

            return `/api/companies/for_select?restrictionStartDate=${this.eventWorkingCopy.start}&restrictionEndDate=${this.eventWorkingCopy.end}`
        },
        titleSuggestion() {
            try {
                const eventType = this.eventWorkingCopy?.eventType;
                const eventTypeTitle = this.eventTypes.find(type => type['@id'] === eventType)?.title;

                let companyName = null;
                if (Array.isArray(this.selectedEventCompanies)) {
                    companyName = this.selectedEventCompanies.map(c => c.name).join(', ');
                } else {
                    companyName = this.selectedEventCompanies?.name;
                }
                const eventFormat = this.eventWorkingCopy?.eventFormat;

                if (eventTypeTitle && companyName && eventFormat) {
                    return `${companyName} | ${eventFormat} | ${eventTypeTitle}`;
                } else {
                    return null;
                }
            } catch (e) {
                return null;
            }
        }
    },
    watch: {
        isAllowedToSelectMultipleCompanies(newValue) {
            this.handleIsAllowedToSelectMultipleCompanies(newValue);
        },
        event: {
            handler() {
                this.resetWorkingCopy();
            },
            deep: true,
            immediate: true,
        },
        'eventWorkingCopy.leadUser'(newVal, oldVal) {
            if (newVal) {
                console.log("Setting leadDepartment to ", newVal.contact.contactDepartment);
                this.eventWorkingCopy.leadDepartment = newVal.contact.contactDepartment
            }
        },
        'eventWorkingCopy.eventFormat'() {
            for (const interaction of this.interactionWorkingCopies) {
                interaction.interactionType = this.interactionTypeForInteractions;
            }
        },
        selectedEventCompanies: {
            handler(newCompanies, oldCompanies) {
                if (this.eventWorkingCopy.status === 'Proposed') {
                    let companyIds = [];
                    if (newCompanies instanceof Array && newCompanies.length > 0) {
                        companyIds = newCompanies.map((c) => c.id);
                    } else if (typeof newCompanies === 'object' && newCompanies !== null) {
                        companyIds = [newCompanies.id];
                    }

                    if (companyIds.length > 0) {
                        this.$axios.get(`/api/contacts/for_select?company=${companyIds.join(',')}`)
                            .then(response => {
                                this.initialEventCompanyContactOptions = response.data['hydra:member'];
                            })
                            .catch(() => {
                                this.initialEventCompanyContactOptions = [];
                            })
                    }
                }

                let oldCompanyIds = [];
                if (Array.isArray(oldCompanies)) {
                    oldCompanyIds = oldCompanies.map(x => x.id);
                } else if (typeof oldCompanies === 'object' && oldCompanies !== null) {
                    oldCompanyIds = [oldCompanies.id];
                } else {
                    oldCompanyIds = [];
                }

                let addedCompanies = [];
                if (Array.isArray(newCompanies)) {
                    addedCompanies = newCompanies.filter(x => !oldCompanyIds.includes(x.id));
                } else if (typeof newCompanies === 'object' && newCompanies !== null) {
                    addedCompanies = [newCompanies.id];
                } else {
                    addedCompanies = [];
                }

                // const addedCompanies = newCompanies ? newCompanies.filter(x => !oldCompanyIds.includes(x.id)) : [];

                for (const addedCompany of addedCompanies) {
                    if (addedCompany.isRestricted) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "warning",
                            title: "Restricted Company",
                            message: `The company ${addedCompany.name} you selected is on the restricted list` +
                                (this.eventWorkingCopy.start && this.eventWorkingCopy.end ? ' during the event.' : '.'),
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }
                }

                this.loadKeyAccountManagerSuggestions();
            },
            deep: true,
        },
        conflictCheckTrigger: {
            handler() {
                clearTimeout(this.conflictCheckTimeout);
                this.conflictCheckTimeout = setTimeout(this.loadConflicts, 1500);
            },
            immediate: true
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadTemplates();
            this.loadEventOptions();
            if (this.companyId) {
                this.$axios.get('/api/companies/' + this.companyId).then((res) => {
                    this.selectedEventCompanies.push(res.data)
                })
            }
        });
    },
    methods: {
        handleIsAllowedToSelectMultipleCompanies(value) {
            if (value) {
                if (!Array.isArray(this.selectedEventCompanies)) {
                    if (this.selectedEventCompanies) {
                        this.selectedEventCompanies = [this.selectedEventCompanies];
                    } else {
                        this.selectedEventCompanies = [];
                    }
                }
            } else if (Array.isArray(this.selectedEventCompanies)) {
                if (this.selectedEventCompanies.length > 0) {
                    this.selectedEventCompanies = this.selectedEventCompanies[0];
                } else {
                    this.selectedEventCompanies = null;
                }
            }
        },
        loadTemplates() {
            this.$axios.get('/api/events/templates').then(response => {
                this.templates = response.data;
            });
        },
        async resetWorkingCopy() {
            this.eventWorkingCopy = structuredClone(this.event);

            if (this.eventWorkingCopy.eventHasCompanies) {
                this.eventWorkingCopy.eventHasCompanies.sort((a, b) => {
                    if (a.company.name > b.company.name) {
                        return 1;
                    } else if (a.company.name < b.company.name) {
                        return -1;
                    } else {
                        return 0;
                    }
                });

                this.selectedEventCompanies = this.eventWorkingCopy.eventHasCompanies.map(
                    eventHasCompany => eventHasCompany.company
                );
            } else {
                this.selectedEventCompanies = [];
            }

            if (this.eventWorkingCopy.eventHasCompanyContacts) {
                this.selectedEventCompanyContacts = this.eventWorkingCopy.eventHasCompanyContacts.map(
                    eventHasCompanyContact => eventHasCompanyContact.contact
                );
            } else {
                this.selectedEventCompanyContacts = [];
            }


            // Wait for selectedEventCompanies update handlers before updating is allowedToSelect multiple companies
            await this.$nextTick();
            this.handleIsAllowedToSelectMultipleCompanies(this.isAllowedToSelectMultipleCompanies);

            if (this.eventWorkingCopy.eventTeamMembers) {
                this.selectedTeamMemberUsers = this.eventWorkingCopy.eventTeamMembers.map(
                    teamMember => teamMember.user
                );
            } else {
                this.selectedTeamMemberUsers = [];
            }

            if (this.event.interactions && this.event.interactions.length > 0) {
                this.interactionWorkingCopies = structuredClone(this.event.interactions);
            } else {
                this.interactionWorkingCopies = [];
            }
        },
        addEventGoal() {
            this.eventWorkingCopy.eventGoals.push(structuredClone(this.templates.eventGoal));
        },
        removeEventGoal(index) {
            this.eventWorkingCopy.eventGoals.splice(index, 1);
        },
        addInteraction() {
            const newInteraction = structuredClone(this.templates.interaction);

            newInteraction.timezone = this.eventWorkingCopy.timezone;
            newInteraction.interactionType = this.interactionTypeForInteractions;

            this.interactionWorkingCopies.push(newInteraction);
        },
        removeInteraction(index) {
            this.interactionWorkingCopies.splice(index, 1);
        },
        submitForm() {
            if ((this.eventWorkingCopy.start === null || this.eventWorkingCopy.end === null) /* && this.eventWorkingCopy.status !== "Proposed" */) {
                alert('Dates are required');
                return;
            }

            for (let i = 0; i < this.interactionWorkingCopies.length; i++) {
                if (!this.interactionWorkingCopies[i].start) {
                    alert("Please set a start date/time for interaction" + (i + 1) + ".");
                    return false;
                }

                if (!this.interactionWorkingCopies[i].end) {
                    alert("Please set an end date/time for interaction" + (i + 1) + ".");
                    return false;
                }
            }

            this.save();
        },
        save() {
            this.loading = true;

            const finalEvent = this.prepareIri(this.eventWorkingCopy);
            if (finalEvent.eventGoals) {
                for (let i = 0; i < finalEvent.eventGoals.length; i++) {
                    const finalEventGoal = this.prepareIri(finalEvent.eventGoals[i])
                    finalEventGoal.targetNumber = parseInt(finalEventGoal.targetNumber);
                    finalEvent.eventGoals[i] = finalEventGoal;
                }
            }

            // Check lead user => Replace object types which are incorrectly derived from property name by prepareIri
            if (finalEvent.leadUser) {
                finalEvent.leadUser = finalEvent.leadUser.replace('lead_users', 'users');
            }
            if (finalEvent.leadUser === "") {
                finalEvent.leadUser = null;
            }

            if (finalEvent.leadDepartment) {
                console.log("There is a leadDepartment, replacing lead_departments with contact_departments", finalEvent.leadDepartment);
                finalEvent.leadDepartment = finalEvent.leadDepartment.replace('lead_departments', 'contact_departments');
            }

            if (finalEvent.keyAccountManager) {
                console.log("There is a keyAccountManager, replacing key_account_managers with users", finalEvent.keyAccountManager);
                finalEvent.keyAccountManager = finalEvent.keyAccountManager.replace('key_account_managers', 'users');
            }

            // Having an empty string in there will lead to a 400 error
            if (finalEvent.leadDepartment === "") {
                finalEvent.leadDepartment = null;
            }

            // FIXME This should never be the case as notes do not have (and shall not have!) a serialization group?
            if (finalEvent.notes) {
                for (let i = 0; i < finalEvent.notes.length; i++) {
                    finalEvent.notes[i] = finalEvent.notes[i]['@id'];
                }
            }

            if (this.isNew) {
                finalEvent.eventHasCompanies = this.eventHasCompanies;
                finalEvent.eventHasCompanyContacts = this.eventHasCompanyContacts;
                finalEvent.eventTeamMembers = this.eventTeamMembers;
                finalEvent.interactions = this.prepareInteractionsOfEvent(this.event, this.interactionWorkingCopies, false);

                this.$axios.post('/api/events', finalEvent).then(() => {
                    this.$emit('event-updated');
                }).finally(() => {
                    this.loading = false;
                });

            } else {
                // Prepare companies
                const existingEventHasCompanies = [];
                let allCompanies = [];
                if (Array.isArray(this.selectedEventCompanies)) {
                    allCompanies = this.selectedEventCompanies.map(x => x["@id"]);
                } else if (typeof this.selectedEventCompanies === 'object' &&
                    this.selectedEventCompanies !== null) {
                    allCompanies = [this.selectedEventCompanies['@id']];
                }
                // add already existing companies (if not removed)
                this.eventWorkingCopy.eventHasCompanies.forEach((ehc) => {
                    if (allCompanies.includes(ehc.company["@id"])) {
                        existingEventHasCompanies.push(ehc["@id"]);
                    }
                });
                const existingCompanies = this.eventWorkingCopy.eventHasCompanies.map(x => x.company).map(x => x["@id"]);
                // add new companies
                this.eventHasCompanies.forEach((ehc) => {
                    if (!existingCompanies.includes(ehc.company)) {
                        existingEventHasCompanies.push(ehc);
                    }
                })
                finalEvent.eventHasCompanies = existingEventHasCompanies;

                // Prepare company contacts
                const existingEventHasCompanyContacts = [];
                const allContacts = this.selectedEventCompanyContacts.map(x => x["@id"]);

                // add already existing companies (if not removed)
                this.eventWorkingCopy.eventHasCompanyContacts.forEach((ehcc) => {
                    if (allContacts.includes(ehcc.contact["@id"])) {
                        existingEventHasCompanyContacts.push(ehcc["@id"]);
                    }
                });
                const existingContacts = this.eventWorkingCopy.eventHasCompanyContacts.map(x => x.contact).map(x => x["@id"]);
                // add new companies
                this.eventHasCompanyContacts.forEach((ehcc) => {
                    if (!existingContacts.includes(ehcc.contact)) {
                        existingEventHasCompanyContacts.push(ehcc);
                    }
                })
                finalEvent.eventHasCompanyContacts = existingEventHasCompanyContacts;

                // Prepare event team members
                const existingEventTeamMembers = [];
                const allUsers = this.selectedTeamMemberUsers.map(x => x["@id"]);

                // add already existing companies (if not removed)
                this.eventWorkingCopy.eventTeamMembers.forEach((etm) => {
                    if (allUsers.includes(etm.user["@id"])) {
                        existingEventTeamMembers.push(etm["@id"]);
                    }
                });
                const existingUsers = this.eventWorkingCopy.eventTeamMembers.map(x => x.user).map(x => x["@id"]);
                // add new companies
                this.eventTeamMembers.forEach((etm) => {
                    if (!existingUsers.includes(etm.user)) {
                        existingEventTeamMembers.push(etm);
                    }
                });
                finalEvent.eventTeamMembers = existingEventTeamMembers;

                // Delete everything which does not have to be edited in the form (because data might be awkward)
                delete finalEvent.interactions;
                delete finalEvent.participants;

                this.$axios.patch(this.updateUrl, finalEvent, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then((response) => {
                    this.$emit('event-updated', response.data);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        loadEventOptions() {
            this.$axios.get("/api/events/options").then(response => {
                this.departments = response.data.departments;
                this.eventFormats = response.data.formats;

                this.eventTypes = response.data.eventTypes.map(eventType => {
                    eventType['@id'] = `/api/event_types/${eventType.id}`;
                    return eventType;
                });

                this.eventGoalTypes = response.data.eventGoalTypes.map(eventGoalType => {
                    eventGoalType['@id'] = `/api/event_goal_types/${eventGoalType.id}`;
                    return eventGoalType;
                });

                this.interactionTypes = response.data.interactionTypes.map(interactionType => {
                    interactionType['@id'] = `/api/interaction_types/${interactionType.id}`;
                    return interactionType;
                });
            });
        },
        loadConflicts() {
            if (this.eventWorkingCopy.start && this.eventWorkingCopy.end && this.eventWorkingCopy.timezone) {

                this.$axios.post('/api/events/check_conflicts', {
                    start: this.eventWorkingCopy.start,
                    end: this.eventWorkingCopy.end,
                    timezone: this.eventWorkingCopy.timezone,
                    eventIdsToIgnore: this.eventWorkingCopy.id > 0 ? [this.eventWorkingCopy.id] : []
                })
                    .then((response) => {
                        this.conflictingEvents = response.data;
                    });
            }
        },
        loadKeyAccountManagerSuggestions() {
            const debouncedFunction = this.debounce(() => {
                this.$axios.post('/api/staffing/corporate_access_managers_for_companies', {
                    companyIds: this.selectedEventCompanies.map(x => x.id),
                }).then((response) => {
                    this.selectedKeyAccountManageSuggestions = response.data;
                });
            }, 1000).bind(this);
            debouncedFunction();
        },
    }
}

