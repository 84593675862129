import { render, staticRenderFns } from "./event-contracts-card.vue?vue&type=template&id=76f19c72&scoped=true"
import script from "./event-contracts-card.vue?vue&type=script&lang=js"
export * from "./event-contracts-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76f19c72",
  null
  
)

export default component.exports