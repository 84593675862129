
import ContactName from "../../contacts/contact-name.vue";
import QuintableSearchInput from "../../helpers/quintable-search-input.vue";
import TableFilters from "../../table-filters.vue";
import fileDownload from "../../../mixins/file-download";
import cachedCompanyProvider from "../../../mixins/cached-company-provider";

export default {
    name: "SessionsMeetingRequestsTable",
    components: {TableFilters, QuintableSearchInput, ContactName},
    mixins: [fileDownload, cachedCompanyProvider],
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Company",
                        sort: true,
                    }, {
                        headline: "Company Reps",
                        breakpoint: "all"
                    }, {
                        headline: "Client",
                        sort: true
                    }, {
                        headline: "Attendees",
                        breakpoint: "all"
                    }, {
                        headline: "Client Type",
                    }, {
                        headline: "Status",
                    },
                ],
                pagination: 10,
                ajaxUrl: `/api/events/${this.event.id}/sessions_meeting_requests_list`,
                search: true,
            },
            sortOrder: [{
                index: 0,
                asc: true,
            }],
            filterOptions: {
                clientTypes: [],
                status: [],
            },
            selectedClientTypes: [],
            selectedStatus: [],
            searchQuery: null,
            selectedContacts: [],
            selectedCompanies: [],
            selectedBankers: [],
            selectedAnalysts: [],
            selectedCompanyGroups: []
        };
    },
    computed: {
        filters() {
            return {
                clientType: this.selectedClientTypes,
                status: this.selectedStatus,
                clients: this.selectedContacts.map(x => x.id),
                companies: this.selectedCompanies.map(x => x.id),
                bankers: this.selectedBankers.map(x => x.id),
                analysts: this.selectedAnalysts.map(x => x.id),
                companyGroups: this.selectedCompanyGroups.map(x => x.id),
            }
        }
    },
    mounted() {
        this.loadFilterOptions();

        if (this.$route.query.hasOwnProperty('company') && this.$route.query.company > 0) {
            this.getCompanyById(this.$route.query.company).then((company) => {
                this.selectedCompanies.push(company);
            });
        }
    },
    methods: {
        syncMeetingRequests() {
            this.$axios.post('/api/events/' + this.event.id + '/sync_sessions_meeting_requests')
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Meeting requests will be updated in the background",
                        message: '',
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                });
        },
        loadFilterOptions() {
            this.$axios.get('/api/events/sessions_meeting_requests/filter_options')
                .then((response) => {
                    this.filterOptions = response.data;
                })
        },
        updateSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
        },
        downloadMeetingRequests() {
            this.saveQueryAndExport(
                {search: this.searchQuery, filters: this.filters},
                '/api/events/sessions_meeting_requests/excel_export/' + this.event.id)
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
}
