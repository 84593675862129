
import SearchSelect from "../search-select";
import HintText from "../hint-text";

export default {
    name: "DraftModal",
    components: {HintText, SearchSelect},
    props: {
        email: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            draftUser: null,
            isPrivate: false,
            confirmed: false,
        }
    },
    computed: {
        ownEmail() {
            return this.email && (!this.email.id || !this.email.authorUser || this.email.authorUser.id === this.userInfo.id);
        }
    },
    watch: {
        email: {
            handler(val) {
                this.draftUser = val && val.draftUser ? JSON.parse(JSON.stringify(val.draftUser)) : {
                    "@id": '/api/users/' + this.userInfo.id,
                    id: this.userInfo.id,
                    nameWithCompany: this.userInfo.name_with_company
                };

                this.isPrivate = !!(val && val.isPrivate);

            },
            immediate: true,
            deep: true,
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (!this.confirmed) {
                this.$emit('canceled')
            }
            this.confirmed = false;
        })
    },
    methods: {
        confirm() {
            this.confirmed = true;
            this.$emit('saved', {user: this.draftUser, private: this.isPrivate});
        },
    }
}
