
import LoadingSpinner from "../loading-spinner.vue";

export default {
    name: "AutosaveCellAnswer",
    components: {LoadingSpinner},
    props: {
        initialValue: {
            type: String,
            required: false,
            default: ""
        },
        questionId: {
            type: Number,
            required: true
        },
        companyId: {
            type: Number,
            required: true
        },
        answerId: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            value: null,
            allowUpdates: false,
            loading: false,
            answer: null,
        }

    },
    watch: {},
    async created() {
        this.value = this.initialValue;
        await this.$nextTick();
        this.allowUpdates = true;
    },
    methods: {
        submitUpdate() {
            if (!this.allowUpdates) {
                return;
            }

            this.loading = true;

            if(this.answerId) {
                this.$axios.get(`/api/answer_for_event_questions/${this.answerId}`).then((response) => {
                    this.answer = response.data;
                    this.answer.answer = this.value;
                    this.$axios.patch(`/api/answer_for_event_questions/${this.answerId}`,
                        this.answer,
                        {
                            headers: {
                                'Content-Type': 'application/merge-patch+json',
                            },
                        }
                    ).catch(() => {
                        this.$emit("error");
                    }).finally(() => {
                        this.loading = false;
                        this.$emit("refresh");
                    });

                });
            }else{
                this.$axios.post(`/api/answer_for_event_questions`, {
                    answer: this.value,
                    question: `/api/question_for_events/${this.questionId}`,
                    company: `/api/companies/${this.companyId}`,
                    createdByUser: `/api/users/${this.userInfo.id}`
                }).catch(() => {
                    this.$emit("error");
                }).finally(() => {
                    this.loading = false;
                    this.$emit("refresh");
                });
            }
        }
    },
}
