

import InvestorTargetsTable from "../investor-targets/investor-targets-table.vue";

export default {
    name: "InvestorTargetsCard",
    components: {
        InvestorTargetsTable
    },
    mixins: [],
    props: {
        event: {
            type: Object,
            required: true
        },
        companyId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    mounted() {
    },
    created() {

    },
    methods: {}
}
