
import LoadingSpinner from "../../loading-spinner.vue";
import iriPreparation from '~/mixins/iri-preparation';
import {ATTENDANCE_OPTIONS} from '~/mixins/attending';

export default {
    name: "AddContactToEventModal",
    components: {LoadingSpinner},
    mixins: [iriPreparation],
    props: {
        initialContact: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            ATTENDANCE_OPTIONS,
            saving: false,
            events: {},
            sessionsTypes: {},
            isAttending: true,
            hasAttended: null,
            id: null,
            contact: null,
            registerInSessions: false,
            sendConfirmation: false,
            visibleCount: 0,
        }
    },
    computed: {},
    watch: {
        initialContact: {
            handler() {
                this.contact = structuredClone(this.initialContact);
            },
            immediate: true,
            deep: true,
        },
        events: {
            handler(newVal) {
                for (const [key, value] of Object.entries(this.events)) {
                    if (!value) {
                        // Remove orphaned sessionsTypes
                        this.sessionsTypes[key] = null;
                    }
                }
            },
            immediate: true,
            deep: true,
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            // this.$formulate.reset('contactRegistrationForm');
            // this.addEmptyContact();
        });
    },
    methods: {
        async submitForm() {

            this.saving = true;

            if (this.events.length > 5) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "error",
                    title: "Error",
                    message: "Cannot add contact to more than 5 events at a time",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                return;
            }

            await this.$nextTick();

            // console.log("Submitting form...", this.events);
            if (Object.keys(this.events).length === 0) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "danger",
                    title: "Error",
                    message: "Please select at least one event",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.saving = false;
                return;
            }


            const requests = [];
            const eventIds = [];
            for (const [key, value] of Object.entries(this.events)) {
                // console.log(key, value);
                // console.log(this.sessionsTypes[key]);

                if (!value || !value.id) {
                    continue;
                }

                eventIds.push(value.id);

                const body = {
                    contact: this.contact['@id'],
                    event: value['@id'],
                    isAttending: this.isAttending,
                    hasAttended: this.hasAttended,
                    confirmationSent: false,
                    registerInSessions: this.registerInSessions,
                };

                if (this.sessionsTypes[key]) {
                    body.sessionsType = this.sessionsTypes[key];
                }

                requests.push(this.$axios.post(`/api/contact_event_participations`, body));
            }


            await Promise.all(requests)
                .then(() => {
                    if (this.sendConfirmation) {
                        this.$axios.post(`/api/events/prepare_confirmation_email/${this.contact.id}`, {
                            eventIds
                        })
                            .then((response) => {
                                const toastId = this.generateUUID();
                                this.addToast({
                                    type: "success",
                                    title: "Success",
                                    message: "Contact has been added to the events and an email draft has been prepared.",
                                    id: toastId,
                                });
                                this.$nextTick(() => {
                                    this.toggleToast(toastId);
                                });

                                this.$router.push('/emails/' + response.data.id);
                            })

                    } else {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Success",
                            message: "Contact has been added to the events",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                    }
                })
                .then(() => {
                    this.$emit("submitted");

                    // Reset the form
                    this.events = {};
                    this.sessionsTypes = {};
                    this.visibleCount = 0;
                    this.isAttending = true;
                    this.hasAttended = null;
                })
                .finally(() => {
                    this.saving = false;
                });

        },
        save(sendConfirmation, registerInSessions = false) {
            this.registerInSessions = registerInSessions;
            this.sendConfirmation = sendConfirmation;

            this.$formulate.submit('contactRegistrationForm');
        }
    },
}
